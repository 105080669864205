import request from "@/utils/request"
export function updateAvatarByFile(data){
    return request({
        url:'/api/users/updateAvatarByFile',
        method:'post',
        data
    })
}
export function updateAvatarByPath(data){
    return request({
        url:'/api/users/updateAvatarByPath',
        method:'post',
        data
    })
}
export function getUserInfo(){
    return request({
        url:'/api/users/info',
        method:'get'
    })
}
export function getUserCenter(params) {
    return request({
        url:'/api/home/user-data',
        method:'get',
        params
    })
}
export function getIntegral(params) {
    return request({
        url:'/api/integral/list',
        method:'get',
        params
    })
}
export function doSignApi(data) {
    return request({
        url:'/api/integral/do-sign',
        method:'post',
        data
    })
}
export function getOrders(params) {
    return request({
        url:'/api/order/list',
        method:'get',
        params
    })
}
export function refundOrder(data) {
    return request({
        url:'/api/order/refund_order',
        method:'post',
        data
    })
}
export function updatePassByOld(data) {
    return request({
        url:'/api/users/updatePass',
        method:'post',
        data
    })
}