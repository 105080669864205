<template>

    <div class="sign">
        <div class="sign-mask" @click.stop="closeLoginDialog"></div>
        <div class="containers">
            <div class="sign-social"><h2>{{formType|formTypeFilter}}</h2></div>
            <div class="sign-form">
                <form v-if="formType == 'login'" @submit.prevent="login" action="javascript:;" id="sign-in">
                    <div v-if="!isWxLogin">
                        <div class="form-item">
                            <input v-model="loginForm.username" type="text" name="edtUserName" class="form-control"
                                   placeholder="账号" required="required"/>
                            <i class="fa fa-user"></i></div>
                        <div class="form-item">
                            <input v-model="loginForm.pwd" type="password" class="form-control" placeholder="密码"
                                   required="required"/>
                            <i class="fa fa-lock" style="font-size:18px;"></i>
                        </div>
                        <div class="sign-submit">
                            <input type="submit" v-loading="loginLoading" class="btn btn-primary signinsubmit-loader"
                                   id="btnPosts" name="btnPosts" value="登录"/>
                            <label @click.stop="toRegister">前往注册</label>
                        </div>
                    </div>
                    <div v-show="isWxLogin">
                        <div>
                            <img id="wx-img"/>
                        </div>
                        <div class="wx-btn" @click="returnHomeLogin">
                            返回
                        </div>
                    </div>
                    <div v-show="!isWxLogin" id="qqLogin" class="third-auth">
                        <a @click.stop="wxLogin" title="微信登录" class="wx-login-btn" href="javascript:;"></a>
                        <a @click.stop="qqLogin" title="QQ登录" class="qq-login-btn" href="javascript:;"></a>
                        <a @click.stop="hbLogin" title="用QQ账户登录" class="hb-login-btn" href="javascript:;"></a>
                    </div>
                </form>
                <form v-if="formType == 'bind_account'" @submit.prevent="bingPhone" action="javascript:;">
                    <div class="form-item">
                        <input v-model="bindPhoneForm.username" name="edtUserName" class="form-control"
                               placeholder="手机号或邮箱" required="required"/>
                        <i class="fa fa-user"></i>
                    </div>
                    <div class="form-item">
                        <input v-model="bindPhoneForm.code" type="number" class="form-control vertical-code"
                               placeholder="短信验证码" required="required"/>
                        <i class="fa fa-lock" style="font-size:18px;"></i>
                        <input @click="sendBindCodeFun" type="button" v-loading="sendLoading" v-model="btnText"
                               class="vertical-code-btn" autocomplete="off"/>
                    </div>
                    <div v-loading="bindLoading" class="sign-submit bind-btn">
                        <input type="submit" class="btn btn-primary signinsubmit-loader" value="绑定"/>
                    </div>

                </form>
                <form v-if="formType == 'register'" @submit.prevent="doRegister" action="javascript:;">
                    <div class="form-item">
                        <input v-model="registerForm.username" name="edtUserName" class="form-control"
                               placeholder="手机号或邮箱" required="required"/>
                        <i class="fa fa-user"></i>
                    </div>
                    <div class="form-item">
                        <input v-model="registerForm.code" name="code" type="number" class="form-control vertical-code"
                               placeholder="短信验证码" required="required"/>
                        <i class="fa fa-shield" style="font-size:18px;"></i>
                        <input @click="doSendRegisterCode" v-loading="sendLoading" type="button" v-model="btnText"
                               class="vertical-code-btn" autocomplete="off"/>
                    </div>
                    <div class="form-item">
                        <input v-model="registerForm.password" name="password" type="password" class="form-control"
                               placeholder="登录密码" required="required"/>
                        <i class="fa fa-lock" style="font-size:18px;"></i>
                    </div>
                    <div class="form-item">
                        <input v-model="registerForm.pwd" name="pwd" type="password" class="form-control"
                               placeholder="确认密码" required="required"/>
                        <i class="fa fa-lock" style="font-size:18px;"></i>
                    </div>
                    <div class="sign-submit bind-btn">
                        <input type="submit" class="btn btn-primary signinsubmit-loader" value="注册"/>
                        <label @click.stop="formType = 'login'">返回登录</label>
                    </div>

                </form>
            </div>
        </div>
    </div>

</template>

<script>
    import {mapMutations, mapGetters} from "vuex"
    import {setCatch, delCatch, showLoading, hideLoading, setToken, encrypt, stringUtil} from "@/utils"
    import {
        login,
        register,
        bindPhoneByCode,
        thirdLogin,
        getWxImgCode,
        getWxUUID,
        getWxToken,
        thirdAuth,
        sendBindCode,
        sendRegisterCode
    } from "@/api/auth"

    let timer;
    export default {
        name: "login",
        data() {
            return {
                loginForm: {
                    username: '',
                    pwd: '',
                    password: ''
                },
                bindPhoneForm: {
                    username: '',
                    code: '',
                    openId: '',
                    authType: ''
                },
                registerForm: {
                    username: '',
                    password: '',
                    code: '',
                    pwd: ''
                },
                bindLoading: false,
                sendLoading: false,
                loginLoading: false,
                formType: 'login',
                isWxLogin: false,
                btnText: '获取验证码'
            }
        },
        filters: {
            formTypeFilter(val) {
                if (val == 'register') {
                    return '账户注册'
                }
                if (val == 'login') {
                    return '账户登录'
                }
                if (val == 'bind_account') {
                    return '账户绑定'
                }
            }
        },
        computed: {
            ...mapGetters(['isOpenLoginDialog'])
        },
        watch: {
            isOpenLoginDialog(newVal) {
                if (newVal) {
                    let bindAccount = window.localStorage.getItem("bind-account")
                    if (bindAccount) {
                        bindAccount = JSON.parse(bindAccount)
                        this.formType = bindAccount.eventType
                        this.bindPhoneForm.authType = bindAccount.authType
                        this.bindPhoneForm.openId = bindAccount.authId
                    }
                }
            }
        },
        methods: {
            ...mapMutations(['setLoginState', 'setOpenLoginDialogState', 'setUserInfo', 'setOpenMusicPlayer']),
            toRegister() {
                this.formType = 'register'
            },
            returnHomeLogin() {
                this.isWxLogin = false
                this.formType = 'login'
            },
            closeLoginDialog() {
                this.setOpenLoginDialogState(false)
                this.setOpenMusicPlayer(true)
            },
            login() {
                let _this = this
                _this.loginLoading = true
                showLoading()
                _this.msg = ''
                _this.loginForm.password = encrypt(_this.loginForm.pwd)
                login(_this.loginForm).then(res => {
                    _this.loginLoading = false
                    _this.loginForm.pwd = ''
                    setToken(res.token)
                    setCatch('yuan-jie-user', res.user)
                    _this.setUserInfo(res.user)
                    _this.setLoginState(true)
                    _this.setOpenLoginDialogState(false)
                    hideLoading()
                }).catch(err => {
                    _this.loginLoading = false
                    hideLoading()
                })
                return false
            },
            bindLogin(params) {
                var _this = this
                setToken(params.token)
                setCatch('yuan-jie-user', params.user)
                _this.setLoginState(true)
                _this.setOpenLoginDialogState(false)
                hideLoading()
                if (params.authType == 1 || params.authType == 12) {
                    delCatch('third-login-page')
                }
            },
            bingPhone() {
                var _this = this
                _this.bindLoading = true
                bindPhoneByCode(_this.bindPhoneForm).then(function (ret) {
                    _this.bindLoading = false
                    if (ret.status == 200) {
                        thirdLogin({
                            authId: _this.bindPhoneForm.openId,
                            authType: _this.bindPhoneForm.authType
                        }).then(function (rt) {
                            setToken(rt.token)
                            setCatch('yuan-jie-user', rt.user)
                            _this.setLoginState(true)
                            _this.setOpenLoginDialogState(false)
                            _this.formType = 'login'
                            window.localStorage.removeItem("bind-account")
                        }, function (er) {

                        })
                    } else {
                        _this.$message.error(ret.message)
                    }
                }, function (err) {
                    _this.bindLoading = false
                })
            },
            doRegister() {
                let _this = this
                if (stringUtil.isEmpty(this.registerForm.password)) {
                    this.$message.error("密码不能为空")
                    return
                }
                if (this.registerForm.password != this.registerForm.pwd) {
                    this.$message.error("两次密码输入不一致")
                    return
                }
                let registerData = JSON.parse(JSON.stringify(this.registerForm))
                registerData["password"] = encrypt(this.registerForm.password)
                this.registerForm = {
                    username: '',
                    password: '',
                    code: '',
                    pwd: ''
                }
                showLoading()
                register(registerData).then(res => {
                    if (res.status == 200) {
                        login(registerData).then(ret => {
                            setToken(ret.token)
                            _this.setUserInfo(ret.user)
                            _this.setLoginState(true)
                            _this.setOpenLoginDialogState(false)
                            hideLoading()
                        }).catch(err => {
                            hideLoading()
                        })
                    }
                }).catch(err => {
                    hideLoading()
                })
            },
            qqLogin() {
                setCatch('third-login-page', this.$route.name)
                QC.Login.showPopup({
                    appId: '101546073',
                    redirectURI: 'https://yuanjkeji.com/qq_login'
                })
            },
            wxLogin() {
                let winName = window.name
                let requestUrl = `https://open.weixin.qq.com/connect/qrconnect?appid=wxe245f6aba721b64d&scope=snsapi_login&redirect_uri=https://yuanjkeji.com/wxLogin:${winName}&state=&login_type=jssdk&self_redirect=default&`
                window.open(requestUrl)
            },
            hbLogin() {
                let clientId = "WVFTyJgys9"
                let winName = window.name
                let redirectUri = encodeURIComponent("https://yuanjkeji.com/ios-upload/hb-redirect/" + winName)
                let requestUrl = "https://account.dcloud.net.cn/oauth2/webAuthorize?client_id=" + clientId + "&redirect_uri=" + redirectUri + "&response_type=code"
                window.open(requestUrl)
            },
            sendBindCodeFun() {
                let _this = this
                if (stringUtil.isEmpty(this.bindPhoneForm.username)) {
                    _this.$message.error("请输入账号")
                    return
                }
                if (!stringUtil.isEmail(this.bindPhoneForm.username) && !stringUtil.isPhone(this.bindPhoneForm.username)) {
                    _this.$message.error("账号格式错误")
                    return
                }
                _this.sendLoading = true
                sendBindCode({
                    account: this.bindPhoneForm.username,
                    authType: this.bindPhoneForm.authType
                }).then(function (res) {
                    _this.sendLoading = false
                    if (res.status == 200) {
                        var timeNum = 60
                        timer = setInterval(function () {
                            if (timeNum == 0) {
                                timeNum = 60
                                _this.btnText = '获取验证码'
                                clearInterval(timer)
                            } else {
                                timeNum--
                                _this.btnText = timeNum + '秒'
                            }
                        }, 1000)
                        _this.$message.success(res.message)
                    } else {
                        _this.$message.error(res.message)
                    }

                }, function (err) {
                    _this.sendLoading = false
                })
            },
            doSendRegisterCode() {
                let _this = this
                if (stringUtil.isEmpty(this.registerForm.username)) {
                    this.$message.error("账户不能为空")
                    return
                }
                if (!stringUtil.isPhone(this.registerForm.username) && !stringUtil.isEmail(this.registerForm.username)) {
                    this.$message.error("请输入邮箱或手机号")
                    return
                }
                _this.sendLoading = true
                sendRegisterCode({
                    account: this.registerForm.username,
                }).then(function (res) {
                    _this.sendLoading = false
                    if (res.status == 200) {
                        var timeNum = 60
                        timer = setInterval(function () {
                            if (timeNum == 0) {
                                timeNum = 60
                                _this.btnText = '获取验证码'
                                clearInterval(timer)
                            } else {
                                timeNum--
                                _this.btnText = timeNum + '秒'
                            }
                        }, 1000)
                        _this.$message.success(res.message)
                    } else {
                        _this.$message.error(res.message)
                    }

                }, function (err) {
                    _this.sendLoading = false
                })
            },
            toLoginWin() {
                this.formType = 'login'
            }
        },
        created() {
            let bindAccount = window.localStorage.getItem("bind-account")
            if (bindAccount) {
                bindAccount = JSON.parse(bindAccount)
                this.formType = bindAccount.eventType
                this.bindPhoneForm.authType = bindAccount.authType
                this.bindPhoneForm.openId = bindAccount.authId
            }
        },
        mounted() {
            var _this = this
            window.addEventListener('message', function (e) {
                var params = e.data;
                if (params.eventType && params.eventType === 'login') {
                    _this.bindLogin(params)
                }
                if (params.eventType && params.eventType === 'bind_account') {
                    _this.formType = params.eventType
                    _this.bindPhoneForm.authType = params.authType
                    _this.bindPhoneForm.openId = params.authId
                    window.localStorage.setItem("bind-account", JSON.stringify({
                        eventType: params.eventType,
                        authId: params.authId,
                        authType: params.authType
                    }))
                    hideLoading()
                    if (params.authType == 1) {
                        delCatch('third-login-page')
                    }
                }
            });
        }
    }
</script>

<style scoped>
    #login {
        box-sizing: border-box;
        display: inline-block;
        position: absolute;
        z-index: 2;
        right: 0;
        float: none;
        padding: 11px;
        height: 40px;
    }

    #wx-img {
        width: 100%;
    }

    .wx-btn {
        width: 100%;
        height: 4rem;
        line-height: 4rem;
        background-color: #45524242;
        text-align: center;
        border-radius: 5px;
        margin-bottom: 20px;
        cursor: pointer;
    }

    h4 {
        font-weight: 500;
        font-family: inherit;
        line-height: 1.1;
    }

    h2 {
        padding: 20px 0;
    }

    .login-error h4 {
        margin: 0 0 10px 0;
        padding: 0 30px;
        color: #e4393c;
    }

    #login i {
        font-size: 25px;
    }

    .sign-show .sign {
        opacity: 1.0;
        visibility: visible
    }

    .sign-show .sign .containers {
        -webkit-transform: rotateX(-0deg) translateZ(0) translateY(0%);
        -moz-transform: rotateX(-0deg) translateZ(0) translateY(0%);
        -ms-transform: rotateX(-0deg) translateZ(0) translateY(0%);
        -o-transform: rotateX(-0deg) translateZ(0) translateY(0%);
        transform: rotateX(-0deg) translateZ(0) translateY(0%)
    }

    .sign {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 999;
        -webkit-perspective: 500px;
        -moz-perspective: 500px;
        -ms-perspective: 500px;
        -o-perspective: 500px;
        perspective: 500px;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        opacity: 0.0;
        visibility: hidden;
        overflow: hidden
    }

    .sign-mask {
        position: fixed;
        cursor: pointer;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.1);
        opacity: 0.90;
        filter: alpha(opacity=80)
    }

    .sign .containers {
        background-color: #ffffff;
        border-radius: 5px;
        min-width: 262px;
        max-width: 400px;
        position: absolute;
        height: fit-content;
        overflow: hidden;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9;
        margin: auto;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        -webkit-transform: rotateX(-20deg) translateZ(200px) translateY(20%);
        -moz-transform: rotateX(-20deg) translateZ(200px) translateY(20%);
        -ms-transform: rotateX(-20deg) translateZ(200px) translateY(20%);
        -o-transform: rotateX(-20deg) translateZ(200px) translateY(20%);
        transform: rotateX(-20deg) translateZ(200px) translateY(20%)
    }

    .sign form {
        padding: 0 30px
    }

    .sign h3 {
        margin: 0 -30px 20px;
        background-color: #fff;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        padding: 25px 50px;
        font-size: 18px;
        color: #3da9f7
    }

    .sign h3 small {
        float: right;
        cursor: pointer;
        color: #bbb;
        font-size: 14px;
        margin-top: 4px
    }

    .sign h6 {
        margin-bottom: 15px;
        position: relative
    }

    .sign .form-item {
        margin-bottom: 15px;
        position: relative;
        display: flex;
    }

    .sign .form-item i {
        position: absolute;
        top: 12px;
        left: 12px;
        font-size: 16px
    }

    .sign .form-control {
        background: #e6eaed;
        border: none;
        outline: none;
        min-height: 40px;
        display: block;
        width: 100%;
        height: 34px;
        padding: 6px 32px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
        -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
        -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    }

    .sign .sign-submit {
        overflow: hidden;
        text-align: left;
        line-height: 0;
        padding-bottom: 20px
    }

    .sign .sign-submit .btn {
        float: right;
        min-width: 100px;
        min-height: 40px;
        display: inline-block;
        padding: 6px 12px;
        margin-bottom: 0;
        font-size: 14px;
        outline: none;
        font-weight: normal;
        line-height: 1.42857143;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-image: none;
        border: 1px solid transparent;
        border-radius: 4px;
        -webkit-appearance: button;
        -webkit-transition: all .2s ease-in;
        -moz-transition: all .2s ease-in;
        -o-transition: all .2s ease-in;
        transition: all .2s ease-in;
    }

    .sign .sign-submit .btn:hover {
        color: #fff;
        background-color: #3e3e3e;
    }

    .btn-primary {
        color: #fff;
        background-color: #737f99;
        border-color: #2e6da4;
    }

    .sign .sign-submit label {
        margin-top: 20px;
        font-weight: normal;
        display: inline-block;
        max-width: 100%;
        font-size: 14px;
        color: #007aff;
        cursor: pointer;
    }

    .sign .sign-submit label input {
        position: relative;
        top: 2px;
        margin-right: 4px
    }

    .sign-tips {
        position: absolute;
        bottom: 0;
        background-color: #3e3e3e;
        left: 0;
        right: 0;
        height: 6px;
        line-height: 29px;
        padding: 0 50px;
        color: #fff;
        font-size: 12px;
        overflow: hidden
    }

    .sign-show .sign {
        opacity: 1.0;
        visibility: visible;
    }

    .sign-show .sign .containers {
        -webkit-transform: rotateX(-0deg) translateZ(0) translateY(0%);
        -moz-transform: rotateX(-0deg) translateZ(0) translateY(0%);
        -ms-transform: rotateX(-0deg) translateZ(0) translateY(0%);
        -o-transform: rotateX(-0deg) translateZ(0) translateY(0%);
        transform: rotateX(-0deg) translateZ(0) translateY(0%)
    }

    body.sign-show {
        height: 100%;
        width: 100%;
        overflow: hidden
    }

    .sign-social {
        border-bottom: 1px solid #eaeaea;
        margin-bottom: 20px;
        overflow: hidden
    }

    .sign-social h2 {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
    }

    .sign-social h2 .cp-hello {
        text-align: center;
        line-height: 22px;
        font-size: 20px;
        font-weight: bold;
    }

    .sign-content {
        padding: 0 30px;
        text-align: center;
    }

    .sign-content p {
        text-align: left;
    }

    .sign-content p i {
        -webkit-animation: fa-spin 2s infinite linear;
        animation: fa-spin 2s infinite linear;
    }

    .sign-function ul li {
        width: 33%;
        float: left;
        margin-top: 20px;
    }

    .sign-function ul li a {
        font-weight: bold
    }

    .sign-exit {
        padding-top: 36px;
    }

    .sign-exit a {
        width: 100%;
        background: #3e3e3e;
        color: #FFFFFF;
        display: block;
        padding: 12px 0;
        border-radius: 36px;
    }

    .sign-exit a:hover {
        background: #737f99;
    }

    /*第三方登录*/
    .third-auth {
        text-align: center;
        position: relative;
        margin-bottom: 20px;
    }

    .third-auth a {
        color: #666;
        margin: 0px 20px;
        width: 40px;
        height: 40px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        display: inline-block;
        border-radius: 4px;
        zoom: 1;
        background-size: 100% 100%;

    }

    .wx-login-btn {
        background: #fff url(../../assets/image/wx_login.png) no-repeat center center;
    }

    .qq-login-btn {
        background: #fff url(../../assets/image/qq_login.png) no-repeat center center;
    }

    .hb-login-btn {
        background: #fff url(../../assets/image/hb_login.png) no-repeat center center;
    }

    .third-auth a.dt {
        background-position: right;
    }

    .third-auth a.wx {
        background-position: center;
    }

    .third-auth a.qq {
        background-position: 0 0;
    }

    /*注册页面*/
    .vertical-code {
        float: left;
        width: 70% !important;
    }

    .vertical-code-btn {
        float: right;
        width: auto;
        position: absolute;
        min-width: 100px;
        min-height: 40px;
        display: inline-block;
        margin-bottom: 0;
        right: 0px;
        border: 1px solid #E0E0E0;
        border-radius: 4px;
        cursor: pointer;
    }

    .bind-btn {
        margin-top: 20px;
    }
</style>