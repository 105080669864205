import Cookies from 'js-cookie'
import store from '@/store'
import JSEncrypt from 'jsencrypt/bin/jsencrypt.min'
import { Loading } from 'element-ui'

const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDBSYtNb6neLrrwsuPBsIFjpZBrffdkA8bpp2S35o2TCdhfdxS0nc4pkv9cJLkUvFa+gdQ5nLifnK9B1XoVbIQwY212QAftTDbl77bcHu7GAbv2TZr9pelSeUm1SrtMK5HDr/LzxTutGr4DovVHiDgEn45GQ1X5U+zC0Jp4Awn6ZwIDAQAB'
let loadingInstance = null;
/**
 * 获取token
 * @returns {*}
 */
export function getToken() {
    return Cookies.get(store.state.tokenKey)
}
export function setToken(token) {
    return Cookies.set(store.state.tokenKey, token)
}
export function getCatch(key) {
    return Cookies.getJSON(key)
}
export function setCatch(key,data) {
    return Cookies.set(key,data)
}
export function delCatch(key,data) {
    return Cookies.remove(key)
}
export function objectIsNull(data) {
    return Object.getOwnPropertyNames(data).length === 0
}
export function openPlayer() {
    store.state.isOpenPlayerDialog = true
}
export function closePlayer() {
    store.state.isOpenPlayerDialog = false
}
export function calcPageCount(totalRecord,pageSize) {
    return totalRecord > 0 ? ((totalRecord < pageSize) ? 1 : ((totalRecord % pageSize) ? (parseInt(totalRecord / pageSize) + 1) : (totalRecord/pageSize))) : 0
}
/**
 * 是否为图片文件
 * @param path 图片路径
 */
export function isImg(path) {
    if (typeof path !== 'string') {
        return false
    }
    path = path.toLowerCase()
    return /(jpg|jpeg|png|bmp|gif)$/.test(path)
}
export function isEmpty(str) {
    if(typeof str == "undefined" || str == null || str == ""){
        return true;
    }else{
        return false;
    }
}
export function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','); var mime = arr[0].match(/:(.*?);/)[1]
    var bstr = atob(arr[1]); var n = bstr.length; var u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
}
// 加密
export function encrypt(txt) {
    const encryptor = new JSEncrypt()
    encryptor.setPublicKey(publicKey) // 设置公钥
    return encryptor.encrypt(txt) // 对需要加密的数据进行加密
}


export function transTime(time) {
    var duration = parseInt(time);
    var minute = parseInt(duration/60);
    var sec = duration%60+'';
    var isM0 = ':';
    if(minute == 0){
        minute = '00';
    }else if(minute < 10 ){
        minute = '0'+minute;
    }
    if(sec.length == 1){
        sec = '0'+sec;
    }
    return minute+isM0+sec
}
export function makeDurationToSeconds(str) {
    var arr = str.split(':');
    if (arr.length == 3){
        var hs = parseInt(arr[2]*3600);
        var ms = parseInt(arr[1]*60);
        var ss = parseInt(arr[0]);
        var seconds = hs + ms + ss;
        return seconds;
	}
    if (arr.length == 2){
        var ms = parseInt(arr[0]*60);
        var ss = parseInt(arr[1]);
        var seconds = ms + ss;
        return seconds;
    }
	return 0
}
export function showLoading() {
     loadingInstance = Loading.service({ fullscreen: true });
}
export function hideLoading() {
	if (loadingInstance){
        loadingInstance.close()
	}
}
export function getUrlParam(parameName) {
    // / 获取地址栏指定参数的值
    // / <param name="parameName">参数名</param>
    // 获取url中跟在问号后面的部分
    var parames = window.location.search
    // 检测参数是否存在
    if (parames.indexOf(parameName) > -1) {
        var parameValue = ''
        parameValue = parames.substring(parames.indexOf(parameName), parames.length)
        // 检测后面是否还有参数
        if (parameValue.indexOf('&') > -1) {
            // 去除后面多余的参数, 得到最终 parameName=parameValue 形式的值
            parameValue = parameValue.substring(0, parameValue.indexOf('&'))
            // 去掉参数名, 得到最终纯值字符串
            parameValue = parameValue.replace(parameName + '=', '')
            return parameValue
        }
        return ''
    }
}
export var stringUtil = {
	/**
	 * 验证是否是手机号
	 * @param {Object} str
	 * 是返回true,不是false
	 */
	isPhone : function(str) {
		if ((/^1[3456789]\d{9}$/.test(str))) {
			return true;
		}
		return false;
	},
	/**
	 *  是否为电话号
	 * @param {Object} str
	 */
	isTel:function(str){
		if ((/^0\d{2,3}-?\d{7,8}$/.test(str))) {
			return true;
		}
		return false
	},
	/**
	 * 是否是邮箱
	 * @param {Object} str
	 */
	isEmail:function(str){
		if ((/^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/.test(str))) {
			return true
		}
		return false
	},
	/**
	 * 判断对象是否为空对象
	 * @param {Object} obj
	 * 是返回true,不是false
	 */
	isEmpty: function(obj) {
	     if(typeof obj == "undefined" || obj == null || obj == ""){
	         return true;
	     }else{
	         return false;
	     }
	},
	/**
	 * 判断对象是否为字符串
	 * @param {Object} obj
	 * 是返回true,不是false
	 */
	isString: function(str) {
		return (typeof str == 'string');
	},
	/**
	 *  将时间字符串转换回短格式时间
	 * @param {Object} str 
	 *  
	 */
	strToSortDate:function(str){
		var result  = "刚刚"
		if(stringUtil.isEmpty(str) === true){
			return result;
		}
		var dateTimeStamp;
		if(stringUtil.isString(str)){
			str =str.replace(/-/g, '/');//ios这里需要替换下格式
			dateTimeStamp =new Date(str).getTime()
		}else{
			dateTimeStamp = str
		}
		var minute = 1000 * 60;      //把分，时，天，周，半个月，一个月用毫秒表示
	    var hour = minute * 60;
	    var day = hour * 24;
	    var week = day * 7;
	    var halfamonth = day * 15;
	    var month = day * 30;
	    var now = new Date().getTime();   //获取当前时间毫秒
	    var diffValue = now - dateTimeStamp;//时间差
	    if(diffValue < 0){
	        return result;
	    }
	    var minC = diffValue/minute;  //计算时间差的分，时，天，周，月
	    var hourC = diffValue/hour;
	    var dayC = diffValue/day;
	    var weekC = diffValue/week;
	    var monthC = diffValue/month;
	    if(monthC>12){
	    	result = " " + parseInt(monthC/12) + "年前"
	    }else if(monthC >= 1 && monthC <= 12){
	        result = " " + parseInt(monthC) + "月前"
	    }else if(weekC >= 1 && weekC <= 4){
	        result = " " + parseInt(weekC) + "周前"
	    }else if(dayC >= 1 && dayC <= 7){
	        result = " " + parseInt(dayC) + "天前"
	    }else if(hourC >= 1 && hourC <= 24){
	        result = " " + parseInt(hourC) + "小时前"
	    }else if(minC >= 1 && minC <= 60){
	        result =" " + parseInt(minC) + "分钟前"
	    }else if(diffValue >= 0 && diffValue <= minute){
	        result = "刚刚"
	    }else {
	        var datetime = new Date();
	        datetime.setTime(dateTimeStamp);
	        var Nyear = datetime.getFullYear();
	        var Nmonth = datetime.getMonth() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : datetime.getMonth() + 1;
	        var Ndate = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
	        var Nhour = datetime.getHours() < 10 ? "0" + datetime.getHours() : datetime.getHours();
	        var Nminute = datetime.getMinutes() < 10 ? "0" + datetime.getMinutes() : datetime.getMinutes();
	        var Nsecond = datetime.getSeconds() < 10 ? "0" + datetime.getSeconds() : datetime.getSeconds();
	        result = Nyear + "-" + Nmonth + "-" + Ndate
	    }
	    return result;
	},
	formatDate:function(fmt,date){
		var o = {
			"M+": date.getMonth() + 1, //月份
			"d+": date.getDate(), //日
			"h+": date.getHours(), //小时
			"m+": date.getMinutes(), //分
			"s+": date.getSeconds(), //秒
			"q+": Math.floor((date.getMonth() + 3) / 3), //季度
			"S": date.getMilliseconds() //毫秒
		};
		if (/(y+)/.test(fmt))
			fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
		for (var k in o)
			if (new RegExp("(" + k + ")").test(fmt))
				fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
		return fmt;
	},
	formatDateStr:function(fmt,dateStr){
		var date
		if(dateStr){
			dateStr = dateStr.replace(/\-/g, "/")
			date = new Date(dateStr)
		}else{
			date = new Date()
		}
		return stringUtil.formatDate(fmt,date)
	},
	getCurrentNumberDate:function(){
		var curDate = new Date() 
		var yyyy = curDate.getFullYear()
		var mm = parseInt(curDate.getMonth() + 1) > 9 ? parseInt(curDate.getMonth() + 1) : '0' + parseInt(curDate.getMonth() +
			1)
		var dd = curDate.getDate() > 9 ? curDate.getDate() : '0' + curDate.getDate()
		var dateNum = yyyy +'-'+ mm + '-' + dd
		return stringUtil.formatDateStr('yyyyMMdd',dateNum)
	}
}